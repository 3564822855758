import { useState, useEffect } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectTimedOrderSchedule } from 'store/selectors/orderSelectors';
import { SelectData } from 'types/SelectData';
import moment from 'moment-timezone';

/**
 * Округляет минуты момента в направлении midpoint так, чтобы округлённые минуты
 * были кратны offset.
 *
 * @param m Момент времени, который будет округлён
 * @param offset Значение, кратными которому должны стать минуты
 * @param midpoint Направление, в сторону которого минуты будут округляться.
 *   Если значение равно `up`, до минуты будут округляться в бОльшую сторону;
 *   иначе, если значение равно `down`, в меньшую.
 * @returns Момент с округлёнными минутами
 */
const roundMomentMinutes = (
  m: moment.Moment,
  offset: number,
  midpoint: 'up' | 'down'
): moment.Moment => {
  const minutes = m.minutes();

  let roundingFunction: (x: number) => number;
  if (midpoint === 'up') {
    roundingFunction = Math.ceil;
  } else {
    roundingFunction = Math.floor;
  }

  // TODO maybe should add 1 when using ceil
  const roundedMinutes = roundingFunction(minutes / offset) * offset;
  return moment(m).minutes(roundedMinutes);
};

/**
 * Разделяет временной интервал на отрезки одинаковой длины
 * и возвращает список времени на границах отрезков
 * @param start Начало временного интервала
 * @param end Конец временного интервала
 * @param timeInterval Продолжительность временных отрезков
 * @returns Список временных точек между `start` и `end` на расстоянии
 *   друг от друга, заданном `timeInterval`
 */
const splitDuration = (
  start: moment.Moment,
  end: moment.Moment,
  timeInterval: moment.Duration
): SelectData[] => {
  start = roundMomentMinutes(start, timeInterval.minutes(), 'up');
  end = roundMomentMinutes(end, timeInterval.minutes(), 'down');

  start.seconds(0);
  end.seconds(0);

  const timePoints: SelectData[] = [];

  for (
    let currentMoment = start;
    currentMoment <= end;
    currentMoment.add(timeInterval)
  ) {
    timePoints.push({
      label: currentMoment.tz(moment.tz.guess()).format('HH:mm'),
      value: currentMoment.tz(moment.tz.guess()).format('HH:mm'),
    });
  }

  return timePoints;
};

const useTimeList = (selectedDate?: string | moment.Moment | null) => {
  const timedOrderSchedule = useAppSelector(selectTimedOrderSchedule);

  if (timedOrderSchedule == null) {
    return {};
  }

  const dates = Object.keys(timedOrderSchedule);
  const minDate = moment(dates[0]);
  const maxDate = moment(dates[dates.length - 1]);

  if (selectedDate == null) {
    return { minDate, maxDate };
  }

  if (typeof selectedDate === 'string') {
    selectedDate = moment(selectedDate);
  }
  const key = selectedDate.format('YYYY-MM-DD');

  return {
    availableTimeList: splitDuration(
      moment.utc(timedOrderSchedule[key][0]),
      moment.utc(timedOrderSchedule[key][1]),
      moment.duration(15, 'minutes')
    ),
    minDate,
    maxDate,
  };
};

export default useTimeList;
